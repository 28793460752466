<template>
  <client-only>
    <TransitionRoot :show="isOpen" as="template">
      <Dialog
        component-name="modal-filters"
        data-skin="white"
        class="relative z-50"
        data-lenis-prevent
      >
        <TransitionChild
          enter="transition-opacity duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <!-- The backdrop, rendered as a fixed sibling to the panel container -->
          <div
            class="modal-backdrop"
            aria-hidden="true"
            @click="emit('close')"
          ></div>
        </TransitionChild>

        <!-- Full-screen scrollable container -->
        <div
          class="pointer-events-none overflow-clip fixed inset-0 flex justify-end w-full h-full"
        >
          <!-- Container to center the panel -->
          <div class="flex justify-end items-stretch w-full h-full max-h-full">
            <TransitionChild
              enter="transition-transform duration-300 ease-out delay-200"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transition-transform duration-300 ease-in"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <!-- The actual dialog panel -->
              <component
                :is="$device.isMobile ? 'div' : DialogPanel"
                data-skin="white"
                class="pointer-events-auto overflow-y-auto flex flex-col gap-2xl w-[calc(100vw-60px)] max-w-[600px] extdesktop:max-w-[700px] h-full max-h-full bg-skin-base p-edge"
              >
                <wrapper-reveal reveal="fade-from-bottom" class="w-full">
                  <div
                    class="text-heading-4 text-skin-base"
                    v-html="$t.labels.filter_by"
                  ></div>
                </wrapper-reveal>

                <form-filters
                  v-if="$attrs.facets"
                  :facets="$attrs.facets"
                  :total="$attrs.total"
                  class="w-full"
                />
              </component>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </client-only>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const route = useRoute();
const emit = defineEmits(["close"]);

watch(route, (newVal, oldVal) => {
  emit("close");
});
</script>
