<template>
  <div
    component-name="toolbar-filters"
    class="flex items-center justify-between gab-3xl"
  >
    <wrapper-reveal
      tag="section"
      reveal="fade-from-right"
      class="flex flex-col items-start justify-start gap-2xs text-skin-base"
    >
      <div
        class="block text-overline/none whitespace-nowrap"
        v-html="$t.labels.active_filters + `(${totalFilters})`"
      ></div>
      <transition-fade>
        <btn
          v-if="totalFilters"
          :title="$t.labels.reset_filters"
          class="block text-body/none text-skin-muted whitespace-nowrap"
          @click="reset"
        >
          {{ $t.labels.reset_filters }}
        </btn>
      </transition-fade>
    </wrapper-reveal>

    <wrapper-reveal
      tag="section"
      reveal="fade-from-left"
      class="flex justify-end"
    >
      <btn-pill
        size="small"
        icon="filters"
        :title="$t.labels.filters"
        @click.stop.prevent="isOpen = true"
      />
    </wrapper-reveal>

    <modal-filters
      :is-open="isOpen"
      :facets="$attrs.facets"
      :total="$attrs.total"
      @close="isOpen = false"
    />
  </div>
</template>

<script setup>
const isOpen = ref(false);
const totalFilters = ref(0);
const router = useRouter();
const route = useRoute();

watch(
  () => route.query,
  (query) => {
    // for each key in query split the value by comma and count the total
    totalFilters.value = Object.keys(query).reduce((acc, key) => {
      return acc + query[key].split(",").length;
    }, 0);
  },
  {
    immediate: true,
  }
);

function reset(event) {
  router.replace({
    path: route.path,
    query: "",
  });
}
</script>
